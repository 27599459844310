<template>
  <div id="mobile-navi" :class="{active: showMobileNavi}">
    <transition name="fade">
      <div id="mobile-navi-wrapper" @click="hideMobileNavi" v-if="showMobileNavi">
        <router-link class="mobile-menu" to="/">首页</router-link>
        <router-link class="mobile-menu" to="/evaluation-service">
          评估服务
        </router-link>
        <router-link class="mobile-menu" to="/tools">在线评估</router-link>
        <router-link class="mobile-menu" to="/app-download">APP下载
        </router-link>
        <router-link class="mobile-menu" to="/aboutus">关于我们</router-link>
      </div>
    </transition>
  </div>
</template>
<script>
import "./styles/style.scss"

export default {
  name: 'HeaderMobileNavi',
  props: ['showMobileNavi'],
  data() {
    return {}
  },
  methods: {
    hideMobileNavi() {
      this.$emit('mobileNaviToggle', false)
    }
  }
}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
