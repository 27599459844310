<template>
  <div id="navibar" :class="{transparent: matchedPath === '/' && !showMobileNavi && !scrolledToMain}">
    <div class="navibar-main">
      <a class="logo" href="/"></a>
      <div class="pc-menu-wrapper hidden-md-and-down">
        <router-link class="pc-menu" to="/" :class="{active: matchedPath === '/'}">首页</router-link>
        <router-link class="pc-menu" to="/evaluation-service" :class="{active: matchedPath === '/evaluation-service'}">
          评估服务
        </router-link>
        <router-link class="pc-menu" to="/tools" :class="{active: matchedPath.startsWith('/tools')}">在线评估</router-link>
        <router-link class="pc-menu" to="/app-download" :class="{active: matchedPath === '/app-download'}">APP下载
        </router-link>
        <router-link class="pc-menu" to="/aboutus" :class="{active: matchedPath === '/aboutus'}">关于我们</router-link>
        <a class="pc-menu login-menu" href="http://new.cheyintong.com" target="_blank" >登录系统</a>
      </div>
      <div class="mobile-toggle-wrapper hidden-lg-and-up" @click="mobileToggleClick">
        <div class="mobile-toggle">
          <div class="mobile-toggle-icon" :class="{active: showMobileNavi}"></div>
          <span class="mobile-toggle-text">首页</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "./styles/mobile.scss";
import "./styles/pc/pc.scss";

export default {
  name: 'Header',
  props: ['showMobileNavi', 'scrolledToMain'],
  data() {
    return {
      showMobileMenu: false,
      matchedPath: '/'
    }
  },
  watch: {
    $route: function (newValue) {
      const {path} = newValue
      this.matchedPath = path
    }
  },
  methods: {
    mobileToggleClick() {
      this.$emit('scrollToTop')
      setTimeout(() => {
        this.$emit('mobileNaviToggle', !this.showMobileNavi)
      }, 50)
    },
  }

}
</script>
