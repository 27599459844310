import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', component: Home
  },
  {
    path: '/aboutus',
    name: 'Aboutus',
    component: () => import(/* webpackChunkName: "about" */ '../views/Aboutus_new')
  },
  {
    path: '/evaluation-service',
    name: 'Pinggufuwu',
    component: () => import(/* webpackChunkName: "pinggufuwu" */ '../views/Pinggufuwu/Pinggufuwu.vue')
  },
  {
    path: '/tools',
    name: 'Tools',
    component: () => import('../views/Tools')
  },
  {
    path: '/tools-pinggu',
    name: 'Tools-pinggu',
    component: () => import('../views/Tools-pinggu')
  },
  {
    path: '/tools-pgResult',
    name: 'Tools-pgResult',
    component: () => import('../views/Tools-pgResult')
  },
  {
    path: '/app-download',
    name: 'app-download',
    component: () => import('../views/AppDownload')
  },
  {
    path: '/tools-peizhi',
    name: 'Tools-peizhi',
    component: () => import('../views/Tools-peizhi')
  },
  {
    path: '/tools-chexing',
    name: 'Tools-chexing',
    component: () => import('../views/Tools-chexing')
  },
  {
    path: '/tools-qianru',
    name: 'Tools-qianru',
    component: () => import('../views/Tools-qianru')
  },
  {
    path: '/tools-baozhi',
    name: 'Tools-baozhi',
    component: () => import('../views/Tools-baozhi')
  },
  {
    path: '/tools-bzResult',
    name: 'Tools-bzResult',
    component: () => import('../views/Tools-bzResult')
  },
  {
    path: '/tools-cxResult',
    name: 'Tools-cxResult',
    component: () => import('../views/Tools-cxResult')
  },
  {
    path: '/tools-vin',
    name: 'Tools-vin',
    component: () => import('../views/Tools-vin')
  },
  {
    path: '/tools-vinResult',
    name: 'Tools-vinResult',
    component: () => import('../views/Tools-vinResult')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
