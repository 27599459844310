<template>
  <div class="homepage-root">
    <!--    banner轮播图-->
    <div class="banner-container-root">
      <el-carousel height="100%" indicator-position="none">
        <el-carousel-item>
          <div class="banner-container banner-container1">
            <div class="banner-left"></div>
            <img class="banner-image" src="@/assets/home-banner.png"/>
            <div class="banner-content-container">
              <div class="banner-content">
                <div class="banner-body">
                  <h3>DATA EVALUATION<br/>
                    FINANCING SOLUTIONS</h3>
                  <p>提供全面的二手车数据、评估、融资业务解决方案</p>
                </div>
                <div class="decoration"></div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="banner-container banner-container2">
            <div class="banner-content">
              <div class="word-wrapper">
                <div class="eng-word">TO ESCORT THE AUTO
                  FINANCE BUSINESS</div>
                <div class="chinese-word">规避车辆估值风险，为汽车金融业务保驾护航</div>
              </div>
              <div class="banner-bg"></div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--    banner卡片-->
    <div class="banner-card-root">
      <div class="banner-card">
        <div class="banner-card-main">
          <h3>OPERATING<br/>SERVICES</h3>
          <p class="title">运营服务</p>
          <div class="divider"></div>
        </div>
        <p class="content">实际业务过程中，会反复涉及培训、流程跟踪、业务协调等人工参与的运营服务工作，为了保障业务的稳定可靠高效，我们配备专业的运营服务团队</p>
        <img class="banner-card-icon" src="@/views/Home/assets/banner-card-icon1.png"/>
      </div>
      <div class="banner-card">
        <div class="banner-card-main">
          <h3>SYSTEM<br/>SERVICES</h3>
          <p class="title">系统服务</p>
          <div class="divider"></div>
        </div>
        <p class="content">提供自主研发，具有知识产权，支持二手车库存融资全链条业务管理，通过业务系统的应用，可以标准化、便捷化管理车辆融资过程中的各环节业务数据管理</p>
        <img class="banner-card-icon" src="@/views/Home/assets/banner-card-icon2.png"/>
      </div>
      <div class="banner-card">
        <div class="banner-card-main">
          <h3>ASSESSMENT<br/>SERVICES</h3>
          <p class="title">评估服务</p>
          <div class="divider"></div>
        </div>
        <p class="content">系统能实现车辆智能化评估，利用机器学习模型精准计算出车辆评估价值，经过车银通鉴定评估的车辆，车银通可对每台车辆价值承担鉴定评估责任</p>
        <img class="banner-card-icon" src="@/views/Home/assets/banner-card-icon3.png"/>
      </div>
    </div>
    <!--    服务的内容-->
    <div class="homepage-section-root">
      <div class="homepage-section-container">
        <div class="section-header">
          <div class="section-header-title">
            服务的内容
          </div>
          <div class="section-header-subtitle">
            CONTENT OF THE SERVICE
          </div>
        </div>
        <div class="service-content">
          <div class="service-content-menu-container">
            <div class="menu" :class="{active: serviceContentIndex === index}"
                 v-for="(menu,index) in serviceContentList" :key="index" @click="handleSCChange(index)">
              {{ menu.title }}
            </div>
          </div>
          <div class="service-content-body-main">
            <div class="service-content-main-header hidden-md-and-down">{{
                serviceContentList[serviceContentIndex].title
              }}
            </div>
            <div class="service-content-main-desc">{{ serviceContentList[serviceContentIndex].desc }}</div>
            <div class="service-content-card-wrapper">
              <div class="service-content-card"
                   v-for="(card,cardIndex) in serviceContentList[serviceContentIndex].cardList" :key="cardIndex">
                <img :src="card.imageUrl"/>
                <div class="service-content-card-content">
                  <div class="title">{{ card.title }}</div>
                  <div class="desc">{{ card.desc }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    服务的对象-->
    <div class="homepage-section-root service-obj-root">
      <div class="homepage-section-container">
        <div class="section-header">
          <div class="section-header-title" style="color: #FFFFFF;">
            服务的对象
          </div>
          <div class="section-header-subtitle" style="color: #555555;">
            OBJECT OF SERVICE
          </div>
        </div>
        <div class="section-body hidden-md-and-down">
          <div class="service-obj-card-container">
            <div class="service-obj-cell" v-for="(item, index) in serviceObjList" :key="index">
              <img class="service-obj-cell-bg" :src="item.bgUrl"/>
              <div class="service-obj-cell-mask"/>
              <div class="service-obj-cell-group1">
                <img class="service-obj-cell-icon" :src="item.iconUrl"/>
                <div class="service-obj-cell-title">{{ item.title }}</div>
              </div>
              <div class="service-obj-cell-desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
        <div class="section-body hidden-lg-and-up">
          <div class="service-obj-card-container">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide v-for="(item, index) in serviceObjList" :key="index">
                <div class="service-obj-cell">
                  <img class="service-obj-cell-bg" :src="item.bgUrl"/>
                  <div class="service-obj-cell-mask">
                    <img class="service-obj-cell-icon" :src="item.iconUrl"/>
                    <div class="service-obj-cell-title">{{ item.title }}</div>
                    <div class="service-obj-cell-desc">{{ item.desc }}</div>
                  </div>
                </div>
              </swiper-slide>
              <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <!--    产品优势-->
    <div class="homepage-section-root product-ada-root">
      <div class="homepage-section-container">
        <div class="section-header">
          <div class="section-header-title">
            我们的产品优势
          </div>
          <div class="section-header-subtitle">
            OUR PRODUCT ADVANTAGE
          </div>
        </div>
      </div>
      <div class="product-ada">
        <div class="product-ada-header-wapper">
          <div class="product-ada-header">
            <div class="product-ada-header-tab" :class="{active: productAdaIndex === 0}" @click="handlePAChange(0)">
              <img src="@/views/Home/assets/product-ada-header-运营优势.svg"/>
              <div class="title">运营优势</div>
            </div>
            <div class="product-ada-header-tab" :class="{active: productAdaIndex === 1}" @click="handlePAChange(1)">
              <img src="@/views/Home/assets/product-ada-header-系统优势.svg"/>
              <div class="title">系统优势</div>
            </div>
            <div class="product-ada-header-tab" :class="{active: productAdaIndex === 2}" @click="handlePAChange(2)">
              <img src="@/views/Home/assets/product-ada-header-评估优势.svg"/>
              <div class="title">评估优势</div>
            </div>
          </div>
        </div>
        <div class="product-ada-body">
          <div class="product-ada-slide" v-if="productAdaIndex === 0">
            <div class="slide1-content">
              <div class="thumbnail"></div>
              <div class="slide1-text-wrapper">
                <div class="title">建立标准化的车辆档案信息</div>
                <div class="desc">收集车辆各类信息，保证车辆信息的全面性</div>
                <div class="desc">信息审核机制：采用双人审核机制，提高车辆信息的准确性</div>
                <div class="desc">车辆档案管理：车辆信息归档，提供高效的车辆档案管理服务</div>
              </div>
            </div>
            <div class="slide1-title">全业务流程专人跟踪 提高业务办理效率</div>
            <div class="product-ada-card-group">
              <div class="product-ada-card" v-for="(item, index) in productAdaList[0]" :key="index">
                <div class="thumbnail" :style="{backgroundImage: `url('${item.imgUrl}')`}"></div>
                <div class="content">
                  <div class="title">{{ item.title }}</div>
                  <div class="desc">{{ item.desc }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="product-ada-slide" v-if="productAdaIndex === 1">
            <div class="slide1-title">为二手车库存融资业务提供专业的系统解决方案</div>
            <div class="product-ada-card-group">
              <div class="product-ada-card" v-for="(item, index) in productAdaList[1]" :key="index">
                <div class="thumbnail" :style="{backgroundImage: `url('${item.imgUrl}')`}"></div>
                <div class="content">
                  <div class="title">{{ item.title }}</div>
                  <div class="desc">{{ item.desc }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="product-ada-slide" v-if="productAdaIndex === 2">
            <div class="product-ada-card-group">
              <div class="product-ada-card" v-for="(item, index) in productAdaList[2]" :key="index">
                <div class="thumbnail" :style="{backgroundImage: `url('${item.imgUrl}')`}"></div>
                <div class="content">
                  <div class="title">{{ item.title }}</div>
                  <div class="desc">{{ item.desc }}</div>
                </div>
              </div>
            </div>
            <div class="slider3-other-content">
              <div class="slide1-title">车银通智能评估＋复评</div>
              <div class="slider3-other-content-body">
                <div class="slider3-other-cell">
                  <img src="@/views/Home/assets/product-ada-1.svg" />
                  <div class="desc">
                    可根据业务定制化采集车辆信息和漆模检测信息，对于特殊类型品牌或车系，系统能自动识别并推荐采集类型和采集点数量，真正做到一车一况
                  </div>
                </div>
                <div class="slider3-other-cell">
                  <img src="@/views/Home/assets/product-ada-2.svg" />
                  <div class="desc">
                    采集上来的图形图像信息能智能化辨别真伪并自动处理，对于自动处理差异化较大的车辆自动流转到人工复评，双重确认有效识别事故车，大幅度降低金融风险
                  </div>
                </div>
              </div>
              <div class="slider3-other-content-body">
                <div class="slider3-other-cell">
                  <img src="@/views/Home/assets/product-ada-3.svg" />
                  <div class="desc">
                    建立车辆数据信息中心，自有车辆残值机器学习模型，拥有核心车辆价值评估算法
                  </div>
                </div>
                <div class="slider3-other-cell">
                  <img src="@/views/Home/assets/product-ada-4.svg" />
                  <div class="desc">
                    行业定制化系统支持，库存融资业务全流程线上服务，建立车辆电子档案管理，实现放款台账电子化
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    合作伙伴-->
    <div class="homepage-section-root">
      <div class="homepage-section-container">
        <div class="section-header">
          <div class="section-header-title">
            合作伙伴
          </div>
          <div class="section-header-subtitle">
            OUR PARTNERS
          </div>
        </div>
        <div class="partner-root">
          <img class="zhongxin-logo" src="@/views/Home/assets/中信-logo.png" />
          <img class="pingan-logo" src="@/views/Home/assets/平安-logo.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import "@/views/Home/styles/mobile.scss"
import "@/views/Home/styles/pc/pc.scss"
import _ from 'lodash'
import {serviceContentList, serviceObjList, productAdaList} from './source-config'

export default {
  name: 'Home',
  data() {
    return {
      serviceContentIndex: 0,
      serviceContentList,
      serviceObjList,
      swiperOptions: {
        autoplay: true,
        slidesPerView: 3,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      productAdaIndex: 0,
      productAdaList
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  mounted() {
  },
  methods: {
    handleSCChange(index) {
      this.serviceContentIndex = index
    },
    handlePAChange: _.throttle(function (index) {
      this.productAdaIndex = index
    })
  }
}

</script>
