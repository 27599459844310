<template>
  <div id="app" @scroll="pageScroll" ref="appRef">
    <Header :showMobileNavi="showMobileNavi" :scrolledToMain="scrolledToMain" @mobileNaviToggle="mobileNaviToggle"
            @scrollToTop="scrollToTop"/>
    <HeaderMobileNavi :showMobileNavi="showMobileNavi" @mobileNaviToggle="mobileNaviToggle"/>
    <router-view/>
    <Footer/>
  </div>
</template>
<script>
import _ from 'lodash'
import Header from '@/components/Header/Header.vue'
import Footer from '@/components/CytFooter'
import HeaderMobileNavi from '@/components/HeaderMobileNavi/HeaderMobileNavi.vue'

export default {
  name: 'Home',
  components: {
    Header,
    HeaderMobileNavi,
    Footer
  },
  data() {
    return {
      showMobileNavi: false,
      scrolledToMain: false
    }
  },
  methods: {
    mobileNaviToggle(flag) {
      this.showMobileNavi = flag
    },
    pageScroll: _.throttle(function (event) {
      if (this.$route.path !== '/') {
        return;
      }
      const target = event.target
      if (target.clientWidth >= 992) {
        // pc端 首页滚动超过663 header切换状态
        this.scrolledToMain = target.scrollTop > 583;
      } else {
        // 移动端 首页滚动超过663 header切换状态
        this.scrolledToMain = target.scrollTop > 426;
      }
    }, 100),
    scrollToTop() {
      this.$refs.appRef && this.$refs.appRef.scrollTo(0, 0)
    }
  }
}
</script>
<style>
#app {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

@media screen and (min-width: 992px) {
  #app {
    padding-top: 80PX;
  }
}

@media screen and (max-width: 991px) {
  #app {
    padding-top: 100px;
  }
}
</style>
