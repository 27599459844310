import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import Vant from 'vant';
import 'vant/lib/index.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import '@/styles/global.css';
import '@/styles/pc/pc.css';
import AmapVueConfig from '@amap/amap-vue/lib/config';
import MetaInfo from 'vue-meta-info'

AmapVueConfig.key = '632faa22ab2bb69b4e21105c8142cbcb';

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(MetaInfo);

new Vue({
  router,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')


