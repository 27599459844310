import scImage1_1 from "@/views/Home/assets/service-content-1-1.svg";
import scImage1_2 from "@/views/Home/assets/service-content-1-2.svg";
import scImage2_1 from "@/views/Home/assets/service-content-2-1.svg";
import scImage2_2 from "@/views/Home/assets/service-content-2-2.svg";
import scImage2_3 from "@/views/Home/assets/service-content-2-3.svg";
import scImage3_1 from "@/views/Home/assets/service-content-3-1.svg";
import scImage3_2 from "@/views/Home/assets/service-content-3-2.svg";
import scImage3_3 from "@/views/Home/assets/service-content-3-3.svg";
import scImage4_1 from "@/views/Home/assets/service-content-4-1.svg";
import scImage4_2 from "@/views/Home/assets/service-content-4-2.svg";
import scImage4_3 from "@/views/Home/assets/service-content-4-3.svg";
import scImage5_1 from "@/views/Home/assets/service-content-5-1.svg";
import scImage5_2 from "@/views/Home/assets/service-content-5-2.svg";
import scImage5_3 from "@/views/Home/assets/service-content-5-3.svg";

import serviceObjBg1 from '@/assets/homepage-service-obj-bg1.png'
import serviceObjBg2 from '@/assets/homepage-service-obj-bg2.png'
import serviceObjBg3 from '@/assets/homepage-service-obj-bg3.png'
import serviceObjBg4 from '@/assets/homepage-service-obj-bg4.png'
import serviceObjBg5 from '@/assets/homepage-service-obj-bg5.png'

import serviceObjIcon1 from '@/views/Home/assets/homepage-service-obj-金融机构.svg'
import serviceObjIcon2 from '@/views/Home/assets/homepage-service-obj-二手车商.svg'
import serviceObjIcon3 from '@/views/Home/assets/homepage-service-obj-主机厂商.svg'
import serviceObjIcon4 from '@/views/Home/assets/homepage-service-obj-二手车交易市场.svg'
import serviceObjIcon5 from '@/views/Home/assets/homepage-service-obj-个人消费者.svg'

import productAdaImg1_1 from '@/assets/homepage-product-ada-card1-1.png'
import productAdaImg1_2 from '@/assets/homepage-product-ada-card1-2.png'
import productAdaImg1_3 from '@/assets/homepage-product-ada-card1-3.png'
import productAdaImg2_1 from '@/assets/homepage-product-ada-card2-1.png'
import productAdaImg2_2 from '@/assets/homepage-product-ada-card2-2.png'
import productAdaImg2_3 from '@/assets/homepage-product-ada-card2-3.png'
import productAdaImg3_1 from '@/assets/homepage-product-ada-card3-1.png'
import productAdaImg3_2 from '@/assets/homepage-product-ada-card3-2.png'
import productAdaImg3_3 from '@/assets/homepage-product-ada-card3-3.png'

const serviceContentList = [
  {
    title: '车辆数据服务',
    desc: '围绕车辆进行的数据查询服务，通过车架号自动识别品牌、车系和车型，提供车辆详细的出厂参数配置信息、车辆维保记录、车辆碰撞记录、车辆交易价格等信息查询服务。',
    cardList: [{
      title: '基础数据查询服务',
      desc: '车型库、车辆详情参数配置',
      imageUrl: scImage1_1
    }, {
      title: '车辆历史查询服务',
      desc: '车辆各地成交价、车辆维修保养记录、车辆历史碰撞记录、车辆残值分析、VIN自动识别',
      imageUrl: scImage1_2
    }]
  }, {
    title: '车辆评估服务',
    desc: '利用图形图象识别技术、事故车鉴定模型、车辆残值评估模型综合分析计算车辆剩余价值，评估后会为每一台车辆提供专业的车况检测评估报告',
    cardList: [{
      title: '车辆检测服务',
      desc: '漆膜检测和事故车检测',
      imageUrl: scImage2_1
    }, {
      title: '系统评估服务',
      desc: '根据车辆残值模式自动完成车辆评估',
      imageUrl: scImage2_2
    }, {
      title: '人工评估服务',
      desc: '提示在线评估和现场评估',
      imageUrl: scImage2_3
    }]
  }, {
    title: '运营服务',
    desc: '为了保障业务的稳定可靠高效，配备专业的运营服务团队，全业务流程专人跟踪，提高整体业务办理效率。',
    cardList: [{
      title: '营销推广服务',
      desc: '了解二手车商的库存情况和融资需求，协助推广二手车业务',
      imageUrl: scImage3_1
    }, {
      title: '建立车辆标准化档案',
      desc: '审核拍摄车辆及手续照片是否符合业务准入要求',
      imageUrl: scImage3_2
    }, {
      title: '业务流程跟踪',
      desc: '业务全过程运营人工跟踪，任一环节在规定时效内未响应，会立即介入、协调、催办',
      imageUrl: scImage3_3
    }]
  }, {
    title: '风险监控服务',
    desc: '持续关注和收集车辆品牌价值、地方政策、社会事件、舆论导向等影响车辆价值的因素和线索，及时发出风险预警。',
    cardList: [{
      title: '贷后风险监控',
      desc: '影响车辆价值的敏感信息监控，经销商、品牌、车型',
      imageUrl: scImage4_1
    }, {
      title: '预警信息推送',
      desc: '敏感信息或者网络事件信息推送',
      imageUrl: scImage4_2
    }, {
      title: '车辆二次评估',
      desc: '对在押超规定天数的车辆进行二次评估，如果存大幅度贬值情况及时发出预警',
      imageUrl: scImage4_3
    }]
  }, {
    title: '业务系统服务',
    desc: '自主研发了“车银融通”系统，系统操作终端包含PC管理端，APP端和微信小程序端，系统将二手车检测评估，质押手续的落实、放款管理、质押物管理等业务场景整合在一起，以数据化、标准化、便捷化的管理手段为二车手库存融资业务提供专业的系统解决方案。',
    cardList: [{
      title: '数据化管理',
      desc: '',
      imageUrl: scImage5_1
    }, {
      title: '标准化管理',
      desc: '',
      imageUrl: scImage5_2
    }, {
      title: '便捷化管理',
      desc: '',
      imageUrl: scImage5_3
    }]
  }]
const serviceObjList = [
  {
    bgUrl: serviceObjBg1,
    title: '金融机构',
    desc: '为金融机构提供库存融资业务风险控制解决方案',
    iconUrl: serviceObjIcon1
  },
  {
    bgUrl: serviceObjBg2,
    title: '二手车商',
    desc: '为二手车商提供收车、评估、管车、营销一体化服务',
    iconUrl: serviceObjIcon2
  },
  {
    bgUrl: serviceObjBg3,
    title: '主机厂商',
    desc: '提供权威数据支持，满足个性化业务需求',
    iconUrl: serviceObjIcon3
  },
  {
    bgUrl: serviceObjBg4,
    title: '二手车交易市场',
    desc: '通过标准化、流程化和系统化的改造，共建规范诚信的二手车交易市场',
    iconUrl: serviceObjIcon4
  },
  {
    bgUrl: serviceObjBg5,
    title: '个人消费者',
    desc: '提供二手车估价服务，买卖二手车简单、省心，不再提心吊胆',
    iconUrl: serviceObjIcon5
  },
]
const productAdaList = [
  [
    {
      imgUrl: productAdaImg1_1,
      title: '业务培训服务',
      desc: '运营人员电话培训具体操作流程，省去大量沟通工作'
    },
    {
      imgUrl: productAdaImg1_2,
      title: '前端服务',
      desc: '运营人员保持与客户的密切沟通，主动向客户了解是否有二手车库存，协助客户经理引导客户多开展二手车业务，促进二手车业务的发展'
    },
    {
      imgUrl: productAdaImg1_3,
      title: '中后台服务',
      desc: '通过系统信息推动+人工介入的方式，任一环节在规定时效内未响应，运营人员会立即介入，进行催办，同时进行答疑，提高业务办理时效'
    },
  ],
  [
    {
      imgUrl: productAdaImg2_1,
      title: '车辆数据信息中心',
      desc: '支持全品牌车辆历史出厂信息查询'
    },
    {
      imgUrl: productAdaImg2_2,
      title: '掌握核心评估算法',
      desc: '车辆残残值模型算法，实行智能评估＋专家复评'
    },
    {
      imgUrl: productAdaImg2_3,
      title: '图片图像智能识别',
      desc: '智能识别车架号、行驶证、合格证等证件信息，智能识别图片和视频信息'
    },
  ],
  [
    {
      imgUrl: productAdaImg3_1,
      title: '评估师人工评估',
      desc: '主要由二手车商或二手车评估机构组成，如人人车、瓜子或区域二手车商等，主要应用线下二手车买卖业务'
    },
    {
      imgUrl: productAdaImg3_2,
      title: '线上在线评估',
      desc: '简单粗暴的评估方法，选择固定的品牌车系，车辆年限、公里数等车辆信息，套用市场公开的车辆评估公式，进行车辆评估价格。因为技术实现简单，通常是免费提供服务，如汽车之家等线上平台'
    },
    {
      imgUrl: productAdaImg3_3,
      title: '买卖数据为主',
      desc: '由于企业自身背景等原因可以拿到更详细的行业相关数据，由车辆价格等因素反向推算车辆保值率，直接根据车辆保值率来计算车辆评估价格，因为车辆评估是一个复杂的工作，并且过程环节较多，很难真实反映车辆最终价值，这种主要是卖数据为主业，而车辆评估只是辅助服务'
    },
  ],
]

export {serviceContentList, serviceObjList, productAdaList}
